import React from "react"
import { Link } from "gatsby"
import { Container, Navbar, Nav } from "react-bootstrap"

import headerStyles from "./header.module.css"

const Header = ({ siteTitle }) => (
  <Navbar variant="dark" expand="lg" className={headerStyles.navbar}>
    <Container>
      <Link to="/" className="navbar-brand h1 mb-0">
        SV 1961 Gambach e.V.
      </Link>
      <Navbar.Toggle aria-controls="basic-navbar-nav" />
      <Navbar.Collapse id="basic-navbar-nav">
        <Nav className="ml-auto">
          <Link
            to="/"
            activeClassName={headerStyles.navLinkActive}
            className={`nav-link ${headerStyles.navLink}`}
          >
            Start
          </Link>
          <Link
            to="/termine"
            activeClassName={headerStyles.navLinkActive}
            className={`nav-link ${headerStyles.navLink}`}
          >
            Termine
          </Link>
          <Link
            to="/verein"
            activeClassName={headerStyles.navLinkActive}
            className={`nav-link ${headerStyles.navLink}`}
          >
            Verein
          </Link>
          <Link
            to="/training"
            activeClassName={headerStyles.navLinkActive}
            className={`nav-link ${headerStyles.navLink}`}
          >
            Training
          </Link>
        </Nav>
      </Navbar.Collapse>
    </Container>
  </Navbar>
)

export default Header
